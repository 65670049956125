import axios from "axios";
import neoAxios from "@/axios";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import Tags from "@/components/tags";
import Button from "@/components/button";
import Input from "@/components/input";
import ExtLink from "@/components/ext-link";
import Pagination from "@/components/pagination";

import {mapGetters} from "vuex";

import {getSocialProfileIcon, getPlatformName, getPlatformURL, getPlatformRedirectURL} from "@/utils/functions";

export default {
    name: "modal-add-content",
    props: ["heading", "text", "datatype", "card"],
    data() {
        return {
            requireHeading: false,
            flags: [],
            finalTags: [],
            uploadedFiles: [],
            savedFile: "",
            imageCaption: "",
            postingDate: "",
            originalFile: null,
            filterKeyword: "",
            filterKeywordFinal: "",
            totalPageCount: 0,
            currentPage: 1,
        };
    },
    methods: {
        getSocialProfileIcon,
        getPlatformName,
        getPlatformURL,
        getPlatformRedirectURL,
        filterTweets() {
            this.filterKeywordFinal = this.filterKeyword;
            this.currentPage = 1;
        },
        clearFilterTweets() {
            this.filterKeyword = "";
            this.filterKeywordFinal = "";
            this.currentPage = 1;
        },
        getTags(tags) {
            return (
                tags?.map((tag) => {
                    return {tag};
                }) || []
            );
        },
        getDetectedLanguages(data) {
            return data?.languages_detected?.languages
                .filter((e) => e.code != "un")
                .map((f) => f.name)
                .join(", ");
        },
        handleFilePondInit: function () {},
        saveData() {
            let screenshotObj = {
                available: false,
            };
            if (this.savedFile != "") {
                screenshotObj.available = true;
                screenshotObj.location = this.savedFile;
                screenshotObj.file_name = this.originalFile.name;
                screenshotObj.file_mime_type = this.originalFile.type;
                screenshotObj.size = this.originalFile.size;
            }
            let final_data = {
                platform: this.matchingProfile.platform,
                processing: false,
                username: this.matchingProfile.username,
                posting_date: this.postingDate,
                hidden: false,
                deleted: false,
                content: "",
                tweet_id: "",
                language: {},
                metadata: {
                    retweet: false,
                },
                nlp_analysis: {
                    watson: {},
                    nlp_model: {},
                },
                screenshot: screenshotObj,
                image_caption: this.imageCaption,
                categories: this.finalTags,
                editMode: false,
            };
            this.$store.dispatch("addExtraContent", final_data);
        },
        process(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            axios
                .post(`${process.env.VUE_APP_PORTAL_URL}/orca/operator/case/${this.caseId}/file/upload`, formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                    onUploadProgress: (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                })
                .then((response) => {
                    load(response.data);
                    this.savedFile = response.data;
                    this.originalFile = file;
                })
                .catch((error) => {
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                });
        },
        handleError(error, e) {
            switch (e.code) {
                case "storage/canceled":
                    break;
                default:
                    error(e.message);
            }
        },
        includeReport(item) {
            item.flagged = true;
            item["analyst_added"] = true;
            this.$store.dispatch("includeInReport", item);
            this.updateContent(item);
        },
        revertChanges(item) {
            item.flagged = false;
            item["analyst_added"] = false;
            item.categories.analyst = item.categories.system;
            this.$store.dispatch("removeFromReport", item);
            this.updateContent(item, item.categories.system);
        },
        updateContent(item, categories = null) {
            neoAxios
                .put(`/social-platform-data`, {
                    case_id: this.getCaseId,
                    platform: this.getMatchingProfile.platform,
                    username: this.getMatchingProfile.username,
                    content_id: item._id,
                    analyst_added: item?.analyst_added ?? false,
                    include_in_report: item.include_in_report,
                    flagged: item.flagged,
                    categories: categories == null ? item.categories.analyst : categories,
                    translation: item.language?.text_translated_edited ?? item.language?.text_translated ?? item.content,
                })
                .then((response) => {});
        },
        onTagChanges(event, item) {
            let fTags = event.map((e) => e.tag);
            item.categories.analyst = fTags;
            if (item.include_in_report) {
                this.updateContent(item);
            }
        },
    },
    computed: {
        ...mapGetters(["getMatchingProfile", "getCaseId"]),

        matchingProfile() {
            return this.$store.getters.getMatchingCaseData;
        },
        caseId() {
            return this.$store.getters.getCaseId;
        },
        socialData() {
            return this.$store.getters.getMatchingSocialData;
        },
        socialContent() {
            let data = this.$store.getters.getMatchingSocialData;
            let filteredData = [];
            if (data) {
                if (this.filterKeywordFinal == "") {
                    filteredData = data.data;
                    // return data.data
                } else {
                    filteredData = data.data.filter((tweet) => {
                        if (tweet.content.toLowerCase().includes(this.filterKeywordFinal.toLowerCase())) return tweet;
                        else if (tweet.language.text_translated.toLowerCase().includes(this.filterKeywordFinal.toLowerCase())) return tweet;
                    });
                }
            }

            let count = filteredData.length / 5;
            if (count > Math.floor(count)) {
                count = Math.floor(count) + 1;
            }

            this.totalPageCount = count;
            return filteredData.slice(this.currentPage * 5 - 5, this.currentPage * 5);
        },
        gotApiError() {
            let matchingData = this.$store.getters.getMatchingProfile;
            let data = this.$store.getters.getProfileData.find((el) => el.platform === "twitter" && el?.username === matchingData?.username);
            if (data && data?.got_api_error) return data?.got_api_error;
            else return false;
        },

        getTotalPageCount() {
            return this.totalPageCount;
        },
        getFetchedTweet() {
            return this.$store.getters.getMatchingSocialData?.data?.length || false;
        },
        getTotalTweetCount() {
            return this.$store.getters.getMatchingSocialData?.tweet_count || false;
        },
    },
    components: {
        FilePond,
        "neo-datepicker": DatePicker,
        "neo-tags": Tags,
        "neo-button": Button,
        "neo-input": Input,
        "neo-ext-link": ExtLink,
        "neo-pagination": Pagination,
    },
};
